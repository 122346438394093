@import "../../scss/variables.scss";

.movie-card {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 315px;
  margin-bottom: 2rem;
  border-radius: 10px;
  height: calc(100% - 2rem);
  height: -webkit-calc(100% - 2rem);
  height: -moz-calc(100% - 2rem);
  display: inline-block;
}

.movie-card-header {
  padding: 0;
  margin: 0;
  padding-top: calc(442 / 300 * 100%);
  width: 100%;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: cover;
  object-fit: cover;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;
  height: 0;
  img.embed-responsive-item {
    max-height: 100% !important;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    height: 100%;
  }
}

.movie-card-body {
  padding: 18px 18px 24px 18px;
  margin: 0;
  min-height: 100px;
}

.movie-card-title {
  font-size: 1.2rem;
  margin: 0;
  color: #000;
}

.movie-card-year {
  font-size: 12px;
  color: #818181;
  margin: 0;
  font-weight: 600;
}

.movie-card-details-btn {
  display: block;

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: $white;
    margin: 0;
  }
  a {
    border: 1px solid $primary;
    border-radius: 5px;
    padding: 4px 10px;
    height: 26px;
    background: $primary;
    display: block;
    flex: 0 0 auto;
  }
}

.movie-card-action-btn {
  margin: 0 15px 0 0;
  cursor: pointer;
  i {
    color: $primary;
    font-size: 20px;
    text-align: right;
  }
  & + &:last-child {
    margin: 0;
  }
  button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
  }
}

.skeleton-item-container {
  .skeleton-item-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    &:after {
      content: "Loading";
      display: block;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      color: rgba($primary, 1);
      font-size: 1rem;
      opacity: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
  .movie-card-details-btn {
    & > span {
      width: 100%;
    }
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  overflow: hidden;

  &-filmstrip {
    position: relative;
    width: 56px;
    height: 70px;
    background: rgba($primary, 0.8);
    z-index: -1;
    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 120%;
      border-left: 5px dashed white;
      animation: roll 20ms infinite;
    }
    &:before {
      left: 5px;
    }
    &:after {
      right: 5px;
    }
  }
}

@keyframes roll {
  0% {
    top: 0px;
  }
  100% {
    top: -15px;
  }
}
