@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "../scss/variables.scss";

/*===================================*
  01.GENERAL STYLE
*===================================*/
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  scroll-behavior: smooth;
}

* {
  outline: none;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  font:
    normal 400 14px "Poppins",
    sans-serif;
}

body {
  background: $white none repeat scroll 0 0;
  color: #555;
  font-size: 14px;
  line-height: 1.42857143;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #555;
  font-weight: 600;
}

p {
  line-height: 1;
}

a {
  background-color: transparent;
  color: $primary;
  text-decoration: none;
  outline: 0;
  &:active {
    outline: 0;
  }
  &:hover {
    color: $primary;
    outline: 0;
    text-decoration: underline;
  }
  &:visited {
    text-decoration: none;
    outline: 0;
  }
  &:focus {
    color: $primary;
    outline-offset: -2px;
    outline: 5px auto -webkit-focus-ring-color;
    outline: 0;
    text-decoration: none;
  }
  &.disabled,
  &:disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
}

div,
span {
  &.disabled,
  &:disabled,
  &[disabled] {
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
}

a:focus {
  outline: none;
}

// ::selection {
//   color: $white;
//   background: $deep-grey;
// }

img {
  border: 0;
  max-width: 100%;
  page-break-inside: avoid;
  vertical-align: middle;
}

button {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

fieldset {
  border: 0 none;
  margin: 0 auto;
  padding: 0;
}

label {
  margin-bottom: 0;
  line-height: 1;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

iframe {
  border: 0;
  display: block;
}

video {
  width: 100%;
}

.app-content-container {
  margin-left: -15px;
  margin-right: -15px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.575rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-shadow: none;
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none;
  }
}

.prime-btn {
  color: #ffffff;
  background-color: $primary !important;
  border: none !important;

  border-radius: 0.25rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
}

.sec-btn {
  color: $primary;
  background-color: transparent !important;
  border: 1px solid $primary !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: $primary;
    background-color: transparent !important;
    border: 1px solid $primary !important;
    box-shadow: none;
  }
}

.ml-12 {
  margin-left: 12px;
}

::placeholder {
  color: $white;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $white;
}

::-ms-input-placeholder {
  color: $white;
}

svg.icon-loading {
  max-height: 60px;
  top: 20px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.primary-link {
  color: $primary;
  cursor: pointer;
}
