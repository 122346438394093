@import "../../scss/variables.scss";

.tab-wrapper {
  width: 100%;
}

.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  padding: 0.75rem 0;
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-gutter: stable both-edges;
  * {
    z-index: 2;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

input[type="radio"] {
  display: none;

  &:checked + label {
    color: $primary;
    border: 1px solid $primary;
    .notification {
      background-color: $primary;
      color: #fff;
    }
    &::before {
      display: flex;
      //   color: $primary;
      background-color: #e6eef9;
    }
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  padding: 1rem;
  position: relative;
  border: 1px solid #000;
  margin-right: 1rem;
  &::before {
    content: "";
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 5px;
    transition: 0.25s ease-out;
    display: none;
  }
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.75rem;
  border-radius: 50%;
  background-color: #e6eef9;
  transition: 0.15s ease-in;
}

@media (max-width: 700px) {
  .tab {
    font-size: 1rem;
  }
}
