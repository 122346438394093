@import "../../scss/variables.scss";

.app-title-wrapper {
  a {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      text-decoration: none;
    }
  }
}

.app-title {
  color: $primary;
  font-size: 2.5rem;
}

.sub-heading {
  color: #4a4a4a;
  font-size: 1.1rem;
}

input:not([type]).search-input {
  color: #ffffff;
}

.bi {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.search-input {
  border: none;
  // border-bottom: 1px solid #fff;
  color: #fff;
  background-color: $primary;
  //   box-shadow: 0px 10px 40px -10px rgb(255 0 131 / 90%);
  // border-bottom: 1px solid #9e9e9e;
  border-radius: 5px 0 0 5px;
  outline: none;
  height: 2.6rem;
  width: 100%;
  font-size: 1rem;
  // margin: 0 0 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: none;
  box-sizing: border-box;
  line-height: 1;
  transition: all 0.3s;
  flex: 1;
}

.search-btn {
  border-radius: 0 0.25rem 0.25rem 0;
  height: 2.6rem;
  display: flex;
  align-items: center;
  // padding: 0;
}

.prime-btn .icon-loading {
  height: 16px;
  width: 16px;
}

.sticky-search {
  position: fixed;
  top: 0;
  z-index: 999;
  background: $white;
  width: 100%;
  right: 0;
  left: 0;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
  animation: moveDown 0.5s ease-in-out;
  // .btn {
  //   height: 2.4rem;
  // }
  .bi {
    font-size: 17px;
  }
  .btn {
    height: 2.6rem;
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.sub-heading .bi {
  font-size: 12px;
  color: $primary;
}
