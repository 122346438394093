@import "../../scss/variables.scss";

$base-spacing: 12px;
$base-radius: 0.8em;
$poster-width: 140px;
$poster-span: 200px;
$bp-md: 768px;
$bp-sm: 400px;
$imdbColor: #ffae00;
$mcColor: #45b94a;
$rtColor: #ff6347;

h1,
h2,
p,
ul {
  line-height: 1.2;

  &:not(:last-child) {
    margin-bottom: $base-spacing;
  }
}
p {
  color: $black;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5;
  strong {
    color: $black;
    font-size: 1.1rem;
  }
}

.list {
  &-inline li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.movie {
  position: relative;
  overflow: hidden;
  margin: $base-spacing auto;
  width: 100%;
  //   max-width: 800px;
  background-color: white;
  border-radius: $base-radius;
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.07),
    0 1px 2px rgba(0, 0, 0, 0.1);

  &-tags {
    font-size: 0.75em;
    color: rgba(0, 0, 0, 0.5);
    li {
      font-size: 1rem;
      position: relative;
      &:after {
        font-size: 1.1em;
        line-height: 1;
        content: "";
        background-color: $primary;
        width: 5px;
        height: 5px;
        position: absolute;
        border-radius: 50%;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        right: -12px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  &-rating {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-rating-item {
    border: 1px solid $primary;
    border-radius: 5px;
    padding: 4px 10px;
    height: 26px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: $black;
    margin: 0;
    box-shadow:
      0 3px 6px rgba($primary, 0.16),
      0 3px 6px rgba($primary, 0.23);
    @media (max-width: 768px) {
      & {
        background-color: $white;
        color: #fff;
        box-shadow:
          0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }
    span {
      &:first-child {
        margin-right: 10px;
      }
    }
    & + & {
      margin-left: 10px;
    }
    &-imdb {
      border-color: $imdbColor;
      background-color: $imdbColor;
      box-shadow:
        0 3px 6px rgba($imdbColor, 0.16),
        0 3px 6px rgba($imdbColor, 0.23);
    }
    &-mc {
      border-color: $mcColor;
      background-color: $mcColor;
      box-shadow:
        0 3px 6px rgba($mcColor, 0.16),
        0 3px 6px rgba($mcColor, 0.23);
    }
    &-rt {
      border-color: $rtColor;
      background-color: $rtColor;
      box-shadow:
        0 3px 6px rgba($rtColor, 0.16),
        0 3px 6px rgba($rtColor, 0.23);
    }
  }

  &-poster {
    position: relative;
    display: flex;
    width: 100%;

    @media (min-width: $bp-md) {
      margin-right: $base-spacing;
      width: $poster-span;
    }

    &-fill {
      position: absolute;
      overflow: hidden;
      top: -80%;
      bottom: -20%;
      left: -20%;
      width: 150%;
      height: 150%;
      transform: rotate(5deg);

      @media (min-width: $bp-md) {
        top: -20%;
        width: 100%;
      }

      img {
        filter: blur(6px);
        object-fit: cover;
        width: 100%;
        height: 100%;
        transform: scale(1.4);
      }
    }

    &-featured {
      position: relative;
      align-self: center;
      overflow: hidden;
      margin-top: $base-spacing * 7;
      margin-left: $base-spacing * 2;
      width: $poster-width;
      background-color: gainsboro;
      border-radius: 2px;
      box-shadow: rgba(black, 0.6) 0 6px 12px -6px;
      z-index: 1;

      @media (min-width: $bp-md) {
        left: $poster-width / 2.5;
        margin: auto;
      }

      img {
        width: $poster-width;
        vertical-align: middle;

        // &[src="N/A"] {
        //   min-height: 206px;
        //   opacity: 0;
        // }
      }
    }
  }

  &-details {
    flex: 1;
    padding: $base-spacing * 2;

    @media (min-width: $bp-md) {
      padding: $base-spacing * 6;
    }
  }

  &-title {
    font-size: 2em;
    color: $black;
  }

  &-no-results {
    align-self: center;
    justify-self: center;
    width: 100%;
    text-align: center;
  }

  &-btn-wrapper {
    span {
      margin-left: 10px;
    }
  }
}

.ott-wrapper {
  .ott-icon-wrapper {
    height: 4rem;
    width: 4rem;
    background-color: $white;
    border-radius: 4px;
    display: inline-block;
    user-select: none;
    margin-top: 1rem;
    &:not(:last-child) {
      margin-right: 20px;
    }
    &.ott-icon-wrapper-hotstar {
      box-shadow:
        0 1px 3px rgb(0 0 0 / 6%),
        0 1px 2px rgb(0 0 0 / 12%);
    }
  }
  .ott-label-wrapper {
    p {
      margin-bottom: 0;
    }
  }
}

.movie-plot {
  .primary-link {
    margin-left: 10px;
    text-decoration: underline;
    &:empty {
      margin: 0;
      display: none;
    }
  }
  &:empty {
    margin: 0;
  }
}
