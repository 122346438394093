@import "../../scss/variables.scss";

.error-messages {
  color: $black;

  h4 {
    font-size: 1rem;
  }
}

.inspire-text {
  color: lighten($black, 30%);
  font-size: 1rem;
  .bi-emoji-smile,
  .bi-emoji-smile-fill {
    font-size: 2rem;
    color: #ffb319;
  }
}
