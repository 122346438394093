@import "scss/variables.scss";

.fav-clear {
  i,
  span {
    line-height: 1;
  }
}

.fav-header {
  h2 {
    font-size: 1.3rem;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
